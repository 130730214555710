<template>
  <HMAlert @close="$attrs._close()">
    <div class="mb-4">
      <h5 class="d-block mb-2">
        {{ $t('PWA.ADD_MESSAGES.IOS_1') }}
      </h5>
      <img
        class="img-fluid"
        src="https://dv05ui3l6dkej.cloudfront.net/pwa-ios-install-1.png"
      />
    </div>
    <div class="mb-4">
      <h5 class="d-block mb-2">
        {{ $t('PWA.ADD_MESSAGES.IOS_2') }}
      </h5>
      <img
        class="img-fluid"
        src="https://dv05ui3l6dkej.cloudfront.net/pwa-ios-install-2.png"
      />
    </div>
    <div>
      <h5 class="d-block mb-2">
        {{ $t('PWA.ADD_MESSAGES.IOS_3') }}
      </h5>
      <img
        class="img-fluid"
        src="https://dv05ui3l6dkej.cloudfront.net/pwa-ios-install-3.png"
      />
    </div>
  </HMAlert>
</template>

<script lang="ts">
export default {
  name: 'IosPwaModal',
};
</script>
