<template>
  <form @submit.prevent="onConfirm">
    <slot><span v-html="sanitize(text)"></span></slot>
    <HMModalFooter :submitButton="$t('GENERAL.BUTTON.OK')">
      <template v-slot:close>
        <div></div>
      </template>
    </HMModalFooter>
  </form>
</template>

<script>
import { sanitize } from '@/utils/utils';

export default {
  name: 'HMAlert',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  methods: {
    sanitize,
    onConfirm() {
      this.$emit('confirm');
      this.$emit('close');
    },
  },
};
</script>
