<template>
  <div class="add-to-homescreen">
    <div class="d-flex w-100">
      <div>
        <img class="app-icon" :src="appIcon" />
      </div>
      <div class="flex-grow-1 ms-3">
        <h3 class="app-name">{{ studioName }}</h3>
        <p
          class="mb-0"
          v-html="
            $t('PWA.TEXT.CLICK_THE_INSTALL_APP', undefined, { studioName })
          "
        ></p>
        <HMButton
          class="add-button mt-2"
          @click="addToHomescreen"
          color="brand"
        >
          <span class="d-md-none">
            {{ $t('PWA.TITLE.ADD_TO_HOMESCREEN') }}
          </span>
          <span class="d-none d-md-inline">
            {{ $t('PWA.TITLE.INSTALL_APP') }}
          </span>
        </HMButton>
      </div>
    </div>
  </div>
</template>

<script>
import uaParser from 'ua-parser-js';
import i18n from '@/utils/localization';
import { sanitize } from '@/utils/utils';
import IosPwaModal from '@/components/pwa/IosPwaModal';
import HMAlert from '@/components/common/HMAlert';
import { deferedAddToHomescreen } from '@/utils/detect-device';

export default {
  name: 'AddToHomeScreen',
  props: {
    expires: {
      type: Number,
      default: 8,
    },
    studioName: {
      type: String,
      default: undefined,
    },
    appIcon: {
      type: String,
      default: '/assets/images/hey-marvelous-192.png',
    },
  },
  methods: {
    sanitize,
    addToHomescreen() {
      const parsedUa = uaParser(window.navigator);

      if (deferedAddToHomescreen) {
        deferedAddToHomescreen.prompt();
      } else if (parsedUa.os.name === 'iOS') {
        // Open IOS modal only on IOS Safari
        if (parsedUa.browser.name === 'Mobile Safari') {
          this.$modal.show(IosPwaModal, {}, { contentClass: ['vm--alert-modal'] });
        } else {
          this.$modal.show(
            HMAlert,
            {
              text: i18n.t('PWA.ADD_MESSAGES.IOS_OTHERS'),
            },
            { contentClass: ['vm--alert-modal'] }
          );
        }
      } else if (parsedUa.os.name === 'Android') {
        this.$modal.show(
          HMAlert,
          {
            text: i18n.t('PWA.ADD_MESSAGES.ANDROID'),
          },
          { contentClass: ['vm--alert-modal'] }
        );
      } else if (
        parsedUa.os.name === 'Windows' &&
        (parsedUa.browser.name === 'Chrome' || parsedUa.browser.name === 'Edge')
      ) {
        this.$modal.show(
          HMAlert,
          {
            text: i18n.t('PWA.ADD_MESSAGES.WINDOWS.CHROME'),
          },
          { contentClass: ['vm--alert-modal'] }
        );
      } else if (
        parsedUa.os.name === 'Windows' &&
        parsedUa.browser.name === 'Firefox'
      ) {
        this.$modal.show(
          HMAlert,
          {
            text: i18n.t('PWA.ADD_MESSAGES.WINDOWS.FIREFOX'),
          },
          { contentClass: ['vm--alert-modal'] }
        );
      } else if (parsedUa.os.name === 'Mac OS') {
        const isTouchDevice =
          'ontouchstart' in window ||
          navigator.maxTouchPoints > 0 ||
          navigator.msMaxTouchPoints > 0;

        if (isTouchDevice) {
          this.$modal.show(IosPwaModal, {}, { contentClass: ['vm--alert-modal'] });
        } else {
          if (parsedUa.browser.name === 'Firefox') {
            this.$modal.show(
              HMAlert,
              {
                text: i18n.t('PWA.ADD_MESSAGES.MACOS.FIREFOX'),
              },
              { contentClass: ['vm--alert-modal'] }
            );
          } else if (parsedUa.browser.name === 'Chrome') {
            this.$modal.show(
              HMAlert,
              {
                text: i18n.t('PWA.ADD_MESSAGES.MACOS.CHROME'),
              },
              { contentClass: ['vm--alert-modal'] }
            );
          } else if (parsedUa.browser.name === 'Safari') {
            this.$modal.show(
              HMAlert,
              {
                text: i18n.t('PWA.ADD_MESSAGES.MACOS.SAFARI'),
              },
              { contentClass: ['vm--alert-modal'] }
            );
          }
        }
      } else {
        this.$modal.show(
          HMAlert,
          {
            text: i18n.t('PWA.ADD_MESSAGES.OTHERS'),
          },
          { contentClass: ['vm--alert-modal'] }
        );
      }

      this.$emit('finish');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.add-to-homescreen {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.app-name {
  margin: -5px 0 0;
}

.app-icon {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: contain;
}
</style>
